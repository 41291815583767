$msc-invoice-details-container-margin-top:38px;
$msc-invoice-details-container-padding-bottom: 84px;
$msc-invoice-details-header-padding-bottom: 36px;
$msc-invoice-details-invoice-id-padding-bottom: 28px;
$msc-invoice-details-invoice-id-margin-bottom: 31px;
$msc-invoice-details-invoice-info-container-margin-bottom: 47px;
$msc-invoice-details-order-date-padding-right: 10px;
$msc-invoice-details-order-item-count-padding: 0 10px;
$msc-invoice-details-amount-summary-margin-top: 30px;
$msc-invoice-details-amount-due-text-margin-bottom: 8px;
$msc-invoice-details-amount-due-amount-margin-bottom: 6px;
$msc-invoice-details-actions-pay-invoice-margin-top: 30px;
$msc-invoice-details-products-padding-top: 40px;
$msc-invoice-details-product-line-padding-bottom: 44px;
$msc-invoice-details-product-details-padding-left: 5px;
$msc-invoice-details-payment-details-padding-top: 40px;
$msc-invoice-details-payment-details-line-padding-bottom: 16px;
$msc-invoice-details-total-amount-margin-top: 8px;
$msc-invoice-details-total-amount-padding-top: 24px;
$msc-invoice-details-main-info-margin-bottom: 17px;

.msc-invoice-details {
    margin-top: $msc-invoice-details-container-margin-top;
    padding-bottom: $msc-invoice-details-container-padding-bottom;
    border-bottom: 0.5px solid $msv-gray-50;

    &__header {
        padding-bottom: $msc-invoice-details-header-padding-bottom;
        border-bottom: 0.5px solid $msv-gray-50;

        &__invoice-id {
            @include font-heading-h3-l();
            padding-bottom: $msc-invoice-details-invoice-id-padding-bottom;
            border-bottom: 0.5px solid $msv-gray-50;
            margin-bottom: $msc-invoice-details-invoice-id-margin-bottom;
        }

        &__invoice-info-container {
            margin-bottom: $msc-invoice-details-invoice-info-container-margin-bottom;

            @media only screen and (min-width: $msv-breakpoint-l) {
                display: flex;
                justify-content: space-between;
            }

            &__order-info-container {
                &__order-info {
                    &__main-info {
                        @include font-body-regular-m();
                        margin-bottom: $msc-invoice-details-main-info-margin-bottom;
                    }

                    &__details {
                        display: flex;

                        @include font-body-regular-m();

                        &__order-date {
                            padding-right: $msc-invoice-details-order-date-padding-right;
                            border-right: 1px solid $msv-gray-20;
                        }

                        &__order-items-count {
                            padding: $msc-invoice-details-order-item-count-padding;
                        }
                    }
                }
            }

            &__amount-summary {
                @media only screen and (min-width: $msv-breakpoint-l) {
                    text-align: right;
                }

                @media only screen and (max-width: $msv-breakpoint-l) {
                    margin-top: $msc-invoice-details-amount-summary-margin-top;
                }

                &__amount-due-text {
                    @include font-heading-h5-l();
                    margin-bottom: $msc-invoice-details-amount-due-text-margin-bottom;
                }

                &__amount-due-amount {
                    @include font-heading-h3-l();
                    margin-bottom: $msc-invoice-details-amount-due-amount-margin-bottom;
                }

                &__amount-due-date {
                    @include font-body-regular-s();
                    color: $msv-gray-40;
                }
            }
        }

        &__actions {
            &__pay-invoice {
                @include primary-button-light();

                @media only screen and (max-width: $msv-breakpoint-l) {
                    width: 100%;
                    margin-top: $msc-invoice-details-actions-pay-invoice-margin-top;
                }
            }
        }
    }

    &__products {
        padding-top: $msc-invoice-details-products-padding-top;
        border-bottom: 0.5px solid $msv-gray-50;

        &__product-line {
            display: flex;
            justify-content: space-between;
            padding-bottom: $msc-invoice-details-product-line-padding-bottom;

            &__product-info-container {
                display: flex;

                &__product-details {
                    padding-left: $msc-invoice-details-product-details-padding-left;

                    &__name {
                        @include font-body-bold-m();
                    }

                    &__small-details {
                        @include font-body-regular-s();
                    }
                }

                &__name {
                    font-weight: $msv-font-weight-bold;
                    font-size: $msv-font-size-l;
                    line-height: $msv-line-height-l;
                }
            }

            &__total-price {
                @include font-heading-h5-l();
            }
        }
    }

    &__payment-details {
        padding-top: $msc-invoice-details-payment-details-padding-top;

        &__line {
            display: flex;
            justify-content: space-between;
            padding-bottom: $msc-invoice-details-payment-details-line-padding-bottom;

            @include font-body-regular-m();

            &-total-amount {
                margin-top: $msc-invoice-details-total-amount-margin-top;
                padding-top: $msc-invoice-details-total-amount-padding-top;
                border-top: 0.5px solid $msv-gray-50;

                @include font-heading-h5-l();
            }
        }
    }
}
