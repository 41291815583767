[dir="rtl"] {
    .ms-password-reset-verification {
        &__item-error[aria-hidden=false] {
            + input {
                border-left: 0.5px solid $msv-gray-50;
                border-right: 4px solid var(--msv-form-error-font-color);
            }

            &::before {
                margin-right: 0;
                margin-left: 10px;
            }
        }

        &__page-error {
            &::before {
                margin-right: 0;
                margin-left: 10px;
            }
        }

        &__page-success {
            &::before {
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }
}
