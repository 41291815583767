$msv-gift-card-balance-check-input-fields-padding-left: 20px;
$msv-gift-card-balance-check-input-num-fields-padding-left: 20px;
$msv-gift-card-balance-check-button-margin-right: 0;

[dir="rtl"] {
    .ms-gift-card-balance-check {
        &__input-pin-fields {
            padding-left: $msv-gift-card-balance-check-input-fields-padding-left;
            padding-right: 0;
        }

        &__input-error {
            &::before {
                margin-left: 10px;
            }
        }

        @media screen and (min-width: $msv-breakpoint-l) {
            &__input-num-fields {
                padding-left: $msv-gift-card-balance-check-input-num-fields-padding-left;
                padding-right: 0;
            }

            &__btn-apply {
                margin-right: $msv-gift-card-balance-check-button-margin-right;
            }
        }
    }
}
