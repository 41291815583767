$ms-text-block-margin-top: 25px;

//style presets
:root {
    --msv-text-block-font-color: var(--msv-font-primary-color);
    --msv-text-block-font-size-s: var(--msv-body-font-size-s);
    --msv-text-block-font-size-m: var(--msv-body-font-size-m);
    --msv-text-block-font-size-l: var(--msv-body-font-size-l);
    --msv-text-block-font-size-xl: var(--msv-body-font-size-xl);
}

.ms-text-block {
    @include font-body-regular-m();
    margin-top: $ms-text-block-margin-top;

    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }

    &.fontsize__small {
        font-size: var(--msv-text-block-font-size-s);
        line-height: $msv-line-height-s;
    }

    &.fontsize__medium {
        font-size: var(--msv-text-block-font-size-m);
        line-height: $msv-line-height-m;
    }

    &.fontsize__large {
        font-size: var(--msv-text-block-font-size-l);
        line-height: $msv-line-height-l;
    }

    &.fontsize__extralarge {
        font-size: var(--msv-text-block-font-size-xl);
        line-height: $msv-line-height-xl;
    }

    a {
        color: var(--msv-text-block-font-color);
        text-decoration: underline;
    }
}
