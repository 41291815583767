$msv-password-reset-message-width: 67%;
$msv-password-reset-width: 50%;
$msv-password-reset-message-width-t: 75%;
$msv-password-reset-width-t: 67%;
$msv-password-reset-message-width-m: 100%;
$msv-password-reset-width-m: 100%;
$msv-password-reset-message-margin-top: 10px;
$msv-password-reset-message-padding: 20px;
$msv-password-reset-account-item-margin-top: 20px;
$msv-password-reset-button-group-spacing: 20px;
$msv-password-reset-button-margin-top: 20px;
$msv-password-reset-success-alert-bg: transparent;
$msv-password-reset-success-alert-border: transparent;
$msv-password-reset-container-margin-m: 60px 20px;
$msv-password-reset-error-icon-font-size: 20px;

//style presets
:root {
    --msv-password-reset-font-color: var(--msv-font-primary-color);

    // heading
    --msv-password-reset-heading-font-color: var(--msv-font-primary-color);

    // alert
    --msv-password-reset-success-alert-bg: #{$msv-password-reset-success-alert-bg};
    --msv-password-reset-success-alert-font-color: var(--msv-font-primary-color);
    --msv-password-reset-success-alert-border: #{$msv-password-reset-success-alert-border};
}

.ms-password-reset {
    @include font-body-regular-m();
    color: var(--msv-password-reset-font-color);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: $msv-password-reset-message-width;
        margin: 60px 20px;
    }

    &__heading {
        @include font-heading-h2-l();
        color: var(--msv-password-reset-heading-font-color);
        width: $msv-password-reset-width;
        margin: auto;

        @media (min-width: $msv-breakpoint-m+1) and (max-width: $msv-breakpoint-l) {
            @include font-heading-h2-m();
        }

        @media (max-width: $msv-breakpoint-m) {
            @include font-heading-h2-s();
        }
    }

    &__page-error {
        display: none;

        &[aria-hidden=false] {
            display: block;

            .ms-password-reset__page-error-text {
                @include alert();
                display: flex;
                line-height: $msv-password-reset-error-icon-font-size;

                &::before {
                    margin-right: 10px;
                    font-size: $msv-password-reset-error-icon-font-size;
                }
            }
        }
    }

    &__account-items {
        width: $msv-password-reset-width;
        margin: auto;
    }

    &__account-item {
        margin-top: $msv-password-reset-account-item-margin-top;
        display: flex;
        flex-direction: column;

        &-label {
            @include form-input-label();
            order: 1;
        }

        &-input {
            @include form-input-box();
            padding: 14px 75px 14px 12px;
            order: 2;
        }
    }

    &__page-success {
        @include font-body-regular-m();
        background-color: var(--msv-password-reset-success-alert-bg);
        color: var(--msv-password-reset-success-alert-font-color);
        display: none;
        margin-top: $msv-password-reset-message-margin-top;
        border-radius: 10px;
        padding: $msv-password-reset-message-padding;
        border-color: var(--msv-password-reset-success-alert-border);

        &[aria-hidden=false] {
            @include add-icon($msv-Checkbox-Circle-Checked, $icon-weight: $msv-outline-icon-weight);

            &::before {
                color: var(--msv-password-reset-success-alert-font-color);
                margin-right: 13px;
                margin-top: 3px;
                font-size: 20px;
            }
        }
    }

    /* Override inline style inject by AAD so we need important */
    &__page-success[aria-hidden="false"],
    &__page-error[aria-hidden="false"] {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        display: flex !important;
    }

    &__item-error {
        order: 3;

        &[aria-hidden=false] {
            @include alert();
            margin-top: 13px;
            line-height: $msv-password-reset-error-icon-font-size;
            display: flex;

            &::before {
                margin-right: 10px;
                font-size: $msv-password-reset-error-icon-font-size;
            }
        }
    }

    &__buttons {
        margin-top: $msv-password-reset-button-group-spacing;

        &__continue-button {
            margin-top: 0;
        }
    }

    &__continue-button {
        @include primary-button-light();
        margin-top: $msv-password-reset-button-margin-top;
        width: 100%;
        text-transform: uppercase;
        height: auto;
    }

    &__cancel-button {
        @include secondary-button-light();
        margin-top: $msv-password-reset-button-margin-top;
        width: 100%;
        text-transform: uppercase;
        height: auto;
    }

    &__verifying-modal,
    &__verifying-modal-message {
        display: none;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-password-reset-message-width-m;
            margin: 40px 20px 60px;
        }

        &__heading {
            width: $msv-password-reset-width-m;
            color: $msv-black;
        }

        &__account-items {
            width: $msv-password-reset-width-m;
        }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        &__container {
            display: block;
            width: $msv-password-reset-message-width-t;
            margin: $msv-password-reset-container-margin-m;
        }

        &__heading {
            width: $msv-password-reset-width-t;
        }

        &__account-items {
            width: $msv-password-reset-width-t;
        }
    }
}
