$rtl-b2b-sign-in-content-quotes-margin: 40px;
$rtl-b2b-sign-in-banner-details-padding-right: 80px;
$rtl-b2b-sign-in-doublequotes-margin: 20px;

[dir="rtl"] {
    .b2bsignin {
        .signin-container {
            .ms-sign-in {
                right: unset;
                left: 103px;

                &__sign-in-section {
                    border-left: none;
                    padding-left: 0;

                    .ms-sign-in__sign-in-heading {
                        text-align: right;
                    }
                }
            }
        }

        .adventurework-container {
            .ms-content-block {
                &__text {
                    .left-doublequotes {
                        margin-right: 0;
                        margin-left: $rtl-b2b-sign-in-content-quotes-margin;
                    }

                    .right-doublequotes {
                        margin-left: 0;
                        margin-right: $rtl-b2b-sign-in-content-quotes-margin;
                    }
                }

                &__details__additional-content {
                    &__paragraph {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    &-cta {
                        &-links {
                            &:nth-child(1) {
                                border-right: 1px solid $msv-gray-940;
                                border-left: none;
                            }
                        }
                    }
                }
            }
        }

        .b2bsignin-hero-banner .ms-content-block__details {
            padding-left: 0;
            padding-right: $rtl-b2b-sign-in-banner-details-padding-right;
        }

        @media (max-width: $msv-breakpoint-l) {
            .signin-container {
                .ms-content-block__details.withImage {
                    padding-right: $rtl-b2b-sign-in-content-quotes-margin;

                    .ms-content-block__title {
                        text-align: right;
                    }

                    .ms-content-block__text {
                        text-align: right;
                    }

                    .ms-content-block__details__additional-content {
                        text-align: right;
                    }
                }
            }

            .ms-sign-in__container .ms-sign-in__sign-in-section .ms-sign-in__sign-in-disclaimer {
                text-align: right;
            }

            .adventurework-container .ms-content-block__details .ms-content-block__text .left-doublequotes {
                margin-right: 0;
                margin-left: $rtl-b2b-sign-in-doublequotes-margin;
            }

            .adventurework-container .ms-content-block__details .ms-content-block__text .right-doublequotes {
                margin-left: 0;
                margin-right: $rtl-b2b-sign-in-doublequotes-margin;
            }
        }
    }
}
