.single-product-wrapper {
    .ms-buybox__product-title {
        color: #{$kair-dark-blue}!important;
    }

    .msc-buybox__ratings-section-sku-text {
        color: #{$kair-dark-blue}!important; 
    }

    .msc-buybox__ratings-section {
        border-color:  #{$kair-dark-blue}!important;
    }

    .msc-buybox__price-section-text {
        color: #{$kair-dark-blue}!important; 
    }

    .msc-price__actual {
        color: #{$kair-dark-blue}!important; 
    }

    .ms-buybox__quantity {
        border-color:  #{$kair-dark-blue}!important;
    }

    .ms-buybox__product-quantity-label {
        color: #{$kair-dark-blue}!important; 
    }

    .ms-buybox__product-unit-of-measure {
        color: #{$kair-dark-blue}!important; 
    }

    .quantity-colntrols {
        border-color:  #{$kair-dark-blue}!important;
    }

    .quantity {
        color: #{$kair-dark-blue}!important; 
    }

    .quantity-input {
        border-color:  #{$kair-dark-blue}!important;
        color: #{$kair-dark-blue}!important; 
    }

    .msc-add-to-cart  {
        background: #{$kair-dark-blue};
        border-radius: 12px;
        padding: 10px 60px 10px 20px;
        color: #{$kair-white};
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin: 0;
        min-width: unset;
        position: relative;
        cursor: pointer;
        transition: .3s!important;
        border: none!important;

        &:before {
            content: '';
            position: absolute;
            width: 32px;
            height: 32px;
            padding: 4px;
            border-radius: 8px;
            right: 5px;
            top: 5px;
            background: #{$kair-yellow};
        }

        &:after {
            content: '';
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0_236_13' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_236_13)'%3E%3Cpath d='M16.175 13.0001H5C4.71667 13.0001 4.47917 12.9043 4.2875 12.7126C4.09583 12.5209 4 12.2834 4 12.0001C4 11.7168 4.09583 11.4793 4.2875 11.2876C4.47917 11.0959 4.71667 11.0001 5 11.0001H16.175L11.275 6.10011C11.075 5.90011 10.9792 5.66678 10.9875 5.40011C10.9958 5.13344 11.1 4.90011 11.3 4.70011C11.5 4.51678 11.7333 4.42094 12 4.41261C12.2667 4.40428 12.5 4.50011 12.7 4.70011L19.3 11.3001C19.4 11.4001 19.4708 11.5084 19.5125 11.6251C19.5542 11.7418 19.575 11.8668 19.575 12.0001C19.575 12.1334 19.5542 12.2584 19.5125 12.3751C19.4708 12.4918 19.4 12.6001 19.3 12.7001L12.7 19.3001C12.5167 19.4834 12.2875 19.5751 12.0125 19.5751C11.7375 19.5751 11.5 19.4834 11.3 19.3001C11.1 19.1001 11 18.8626 11 18.5876C11 18.3126 11.1 18.0751 11.3 17.8751L16.175 13.0001Z' fill='%23002C59'/%3E%3C/g%3E%3C/svg%3E");
            width: 24px;
            height: 24px;
            right: 8px;
        }

        &:hover {
            background: #{$kair-yellow};
            color: #{$kair-dark-blue};
        }
    }

    .msc-add-to-wishlist {
        display: none!important;
    }

    .msc-buybox__description-text {
        color: #{$kair-dark-blue}!important;
    }

    .msc-btn {
        // border-color: #{$kair-yellow}!important;
        color: #{$kair-dark-blue}!important;
    }

    // .ms-product-specification__drawer {
    //   .msc-btn {
    //     border-color: transparent!important;
    //   }
    // }

    .msc-buybox__description-section-text {
        color: #{$kair-dark-blue}!important;
    }

    .ms-buybox__product-description {
        color: #{$kair-dark-blue}!important;
    }

    .drawer__button {
        color: #{$kair-dark-blue}!important;
    }

    .ms-product-details {
        color: #{$kair-dark-blue}!important;  
    }

    .ms-reviews-list {
        color: #{$kair-dark-blue}!important;  
    }

    .product-legend {
        .row>div {
            display: flex!important;
            align-items: center!important;
        }

        .ms-image-list {
            @media (max-width: 992px) {
                padding-left: 50px;
                padding-right: 50px;
            }
        }

        .ms-image-list__title {
            color: #{$kair-white}!important;
        }

        .ms-image-list__list-item {
            flex-wrap: nowrap;
            gap: 22px;

            a {
                flex-shrink: 0;
            }
        }
    }
}

.product-collection {
    .ms-product-collection__heading {
      color: #002C59!important;
    }
  
    .msc-product__title {
      color: #002C59!important;
    }
  
    .msc-price__actual {
      color: #002C59!important;
      font-weight: 700!important;
    }
  }
  
  .msc-ss-carousel .msc-flipper .msi-chevron-right:before {
    color: #002C59!important;
  }
  
  .msc-ss-carousel .msc-flipper .msi-chevron-left:before {
    color: #002C59!important;
  }
  
  .ms-header {
    .ms-search {
      border-color: #002C59!important;
    }
  }
  
  .ms-product-specification__accordion .ms-product-specification-title:last-child {
    border-color: #002C59!important;
  }
  
  .quantity__controls {
    border-color: #002C59!important;
  }
  
  .ms-buybox .quantity .quantity__controls {
    border-color: #002C59!important;
  }
  
  .single-product-wrapper {
    .ms-header {
      .msc-btn {
        border-color: transparent !important;
      }
    }
  
    .drawer__buttontext {
      color: #002C59!important;
    }
  
    .ms-product-specification__accordion .drawer {
      border-color: #002C59!important;
    }
  
    .ms-buybox .quantity .increment {
      color: #002C59!important;
    }
  
    .ms-buybox .quantity .decrement {
      color: #002C59!important;
    }
  }
  
  .product-legend {
    .ms-image-list__text {
      color: #fff!important;
    }
  
    .ms-image-list {
      padding-left: 50px!important;
      padding-right: 50px!important;
    }
  }