
$msv-alert-message-margin-left: 8px;

[dir="rtl"] {
    .ms-checkout-guest-profile {
        &__input-error {
            + input {
                border-right: 4px solid var(--msv-form-error-font-color);
                border-left: 0.5px solid $msv-gray-50;
            }

            &::before {
                margin-right: unset;
                margin-left: $msv-alert-message-margin-left;
            }
        }
    }
}
