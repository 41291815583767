$ms-quick-order-grid-drawer-margin-right: 6px;
$ms-quick-order-grid-drawer-button-margin-right: 20px;
$ms-quick-order-grid-drawer-button-margin-left: 20px;
$ms-quick-order-grid-headding-margin-right: 0;
$ms-quick-order-grid-headding-margin-left: 0;
$ms-quick-order-grid-description-margin-right: 0;
$ms-quick-order-grid-description-margin-left: 0;
$ms-quick-order-grid-content-margin-left: 0;
$ms-quick-order-grid-content-quantity-margin-right: 11px;
$ms-quick-order-grid-content-form-main-product-margin-left: 11px;
$ms-quick-order-grid-content-form-main-dropdown-margin-right: 11px;
$ms-quick-order-grid-addtocart-mobile-margin-left: 12px;
$ms-quick-order-grid-configure-margin-right: 8px;
$ms-quick-order-grid-configure-mobile-margin-right: 0;
$rtl-msv-quickOrderGrid-alert-icon-margin-left: 10px;
$rtl-msv-alert-icon-margin-left: 22px;

[dir="rtl"] {
    .ms-quick-order-grid {
        &__drawer {
            margin-right: $ms-quick-order-grid-drawer-margin-right;

            &__button {
                text-align: right;

                &[aria-expanded="true"],
                &[aria-expanded="false"] {
                    .ms-quick-order-grid__heading::after {
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
            }

            &-heading {
                margin-right: $ms-quick-order-grid-headding-margin-right;
                margin-left: $ms-quick-order-grid-headding-margin-left;
            }

            &-description {
                margin-right: $ms-quick-order-grid-description-margin-right;
                margin-left: $ms-quick-order-grid-description-margin-left;
            }
        }

        &__content {
            &-form-main {
                &-product {
                    width: auto;
                    margin-right: unset;

                    @media (min-width: $msv-breakpoint-m + 1) {
                        margin-left: $ms-quick-order-grid-content-form-main-product-margin-left;
                    }

                    @media (max-width: $msv-breakpoint-m) {
                        margin-left: auto;
                    }
                }

                &-quantity {
                    @media (min-width: $msv-breakpoint-m + 1) {
                        margin-right: auto;
                        margin-left: unset;
                    }

                    @media (max-width: $msv-breakpoint-m) {
                        margin-right: auto;
                    }
                }

                @media screen and (min-width: $msv-breakpoint-m) {
                    &-add-to-product-list {
                        margin-left: 0;
                        margin-right: $ms-quick-order-grid-addtocart-mobile-margin-left;
                    }
                }

                .msc-alert-danger {
                    .msi-exclamation-triangle {
                        margin-right: 0;
                        margin-left: $rtl-msv-quickOrderGrid-alert-icon-margin-left;
                    }
                }

                &__configure {
                    margin-right: $ms-quick-order-grid-configure-margin-right;
                    margin-left: 10px;
                }

                @media screen and (max-width: $msv-breakpoint-m) {
                    &__configure {
                        margin-right: $ms-quick-order-grid-configure-mobile-margin-right;
                    }
                }

                &__dropdown {
                    @media (min-width: $msv-breakpoint-m + 1) {
                        margin-right: $ms-quick-order-grid-content-form-main-dropdown-margin-right;
                    }

                    @media (max-width: $msv-breakpoint-m) {
                        margin-right: auto;
                    }
                }

                .msc-dropdown__select {
                    margin-left: 10px;
                    margin-right: unset;
                }
            }

            &-form-msg {
                .msc-alert-success {
                    .fa-check {
                        margin-right: 0;
                        margin-left: $rtl-msv-alert-icon-margin-left;
                    }
                }

                .msc-alert-danger {
                    .fa-exclamation-triangle {
                        margin-right: 0;
                        margin-left: $rtl-msv-alert-icon-margin-left;
                    }
                }
            }
        }
    }
}
